import React from "react";
import PropTypes from "prop-types";
import { Button, FormHelperText } from "@mui/material";
import selectors from "selectors";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { formatPhone } from "utils/misc";
import ReorderBanner from "./reorder/banner";
import EligibilityInfo from "./eligibility-info";
import { EligibilityIndicator } from "components/eligibility-indicator";
import useTranslate from "hooks/use-translate-language";
import { supplyOptions } from "./options";
import { getOrderEligibleEquipment } from "./helpers";
import Modal from "components/ui/modal";

const PopularEquipment = [
  "tubing",
  "disposable filter",
  "nondisposable filter",
  "seals",
  "mask",
  "headgear"
];
const BasicEquipment = [
  "tubing",
  "disposable filter",
  "nondisposable filter",
  "seals"
];

const ChooseSupplies = ({ token, showRecallPrompt }) => {
  const { values, setFieldValue, errors } = useFormikContext();
  const translate = useTranslate();
  const patientEquipmentEligibility = useSelector(
    selectors.getPatientEligibility
  );

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );
  const eligibleEquipment = getOrderEligibleEquipment(
    patientEquipmentEligibility,
    companyOrderFormOptions
  );

  const patientInfo = useSelector(selectors.getPatientInfo);

  const handleEligibleConfirmationModal = (selectedEquipment, closeModal) => {
    var element = document.getElementById("eligibleConfirmationModal");
    var eligibleValue = element.getAttribute('selected-eligible-value');
    var values = JSON.parse(eligibleValue);
    setFieldValue("requested_equipment", []);
    setFieldValue("requested_equipment", values);
    setFieldValue("has_not_eligible_selected", true);
    closeModal();
  };

  const checkHasNotEligibleSelected = (currentValue) => {
    let has_not_eligible_selected = false;
    for (const value of values.requested_equipment) {
      const item = patientEquipmentEligibility && patientEquipmentEligibility?.find(phh =>
        phh.productType.toLowerCase()?.includes(value.toLowerCase())
      );
      if (currentValue != value) {
        if (!item.eligible) {
          has_not_eligible_selected = true;
        }
      }
    }
    setFieldValue("has_not_eligible_selected", has_not_eligible_selected);
  };

  const handleSelectedSupplies = (supplies) => {
    let has_not_eligible_selected = false;
    for (const value of JSON.parse(supplies)) {
      const item = patientEquipmentEligibility && patientEquipmentEligibility?.find(phh =>
        phh.productType.toLowerCase()?.includes(value.toLowerCase())
      );
      if (!item.eligible) {
        has_not_eligible_selected = true;
      }
    }

    if (has_not_eligible_selected) {
      var element = document.getElementById("eligibleConfirmationModal");
      element.setAttribute('selected-eligible-value', supplies);
      element.click();
    } else {
      setFieldValue("requested_equipment", JSON.parse(supplies));
      setFieldValue("has_not_eligible_selected", false);
    }
  };

  const patientPhoneNumber = useSelector(state =>
    selectors.getPatientCompanyPhoneNumber(state)
  );
  return (
    <div className="order-form-page">
      {!showRecallPrompt && <ReorderBanner token={token} />}
      <EligibilityInfo />
      <h3 style={{ paddingTop: 10 }}>{translate("chooseSupplies")}</h3>
      <div className="order-support">
        * {translate("issuesOrQuestions")} {formatPhone(patientPhoneNumber)}
      </div>
      <div className="choose-supplies">
        <div className="equipment-select">
          {supplyOptions
            .filter(eq =>
              companyOrderFormOptions?.equipment_type_options?.includes(
                eq.value
              )
            )
            .map(eq => {
              const thisEligibility = patientEquipmentEligibility.length
                ? patientEquipmentEligibility.find(
                  x =>
                    x.productType?.toLowerCase().includes(eq.value) ?? false
                )
                : null;
              return (
                <SupplyCheckbox
                  date={thisEligibility?.eligibilityDate}
                  eligible={thisEligibility?.eligible}
                  key={eq.value}
                  requested_equipment={values.requested_equipment}
                  selectedEquipment={values.requested_equipment}
                  setFieldValue={setFieldValue}
                  {...eq}
                  handleEligibleConfirmationModal={handleEligibleConfirmationModal}
                  checkHasNotEligibleSelected={checkHasNotEligibleSelected}
                  handleSelectedSupplies={handleSelectedSupplies}
                />
              );
            })}
        </div>
        {!patientInfo.new_setup && (
          <div className="choose-supplies-button-group">
            <Button
              onClick={() => {
                handleSelectedSupplies(JSON.stringify(eligibleEquipment));
              }}
              type="button"
              variant="outlined"
              color="primary"
            >
              Eligible Items
            </Button>
            <Button
              onClick={() => {
                handleSelectedSupplies(JSON.stringify(PopularEquipment));
              }}
              type="button"
              variant="outlined"
              color="primary"
            >
              {translate("popular")}
            </Button>
            <Button
              onClick={() => {
                handleSelectedSupplies(JSON.stringify(BasicEquipment));
              }}
              type="button"
              variant="outlined"
              color="primary"
            >
              {translate("basic")}
            </Button>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            padding: "15px 0px"
          }}
        >
          <FormHelperText error>{errors.requested_equipment}</FormHelperText>
        </div>
      </div>
    </div>
  );
};

ChooseSupplies.propTypes = {
  token: PropTypes.string,
  showRecallPrompt: PropTypes.bool
};

export default ChooseSupplies;

const SupplyCheckbox = ({
  selectedEquipment,
  setFieldValue,
  value,
  requested_equipment,
  text,
  description,
  date,
  eligible,
  handleEligibleConfirmationModal,
  checkHasNotEligibleSelected,
  handleSelectedSupplies
}) => {
  return (
    <div key={value}>
      <label>
        <div className="supply-checkbox-container">
          <input
            name="requested_equipment"
            type="checkbox"
            value={value}
            checked={requested_equipment.includes(value)}
            onChange={e => {
              if (!eligible) {
                if (e.target.checked) {
                  var eligibleValue = [...selectedEquipment, value];
                  handleSelectedSupplies(JSON.stringify(eligibleValue));
                } else {
                  setFieldValue(
                    "requested_equipment",
                    selectedEquipment.filter(eq => eq !== value)
                  );
                  checkHasNotEligibleSelected(value);
                }
              } else {
                if (e.target.checked) {
                  setFieldValue("requested_equipment", [
                    ...selectedEquipment,
                    value
                  ]);
                } else {
                  setFieldValue(
                    "requested_equipment",
                    selectedEquipment.filter(eq => eq !== value)
                  );
                }
              }
            }}
          />
        </div>
        <div>
          <div style={{ display: "flex" }}>
            <div>{text}</div>{" "}
            <EligibilityIndicator eligible={eligible} date={date} />
          </div>

          <div className="description">{description}</div>
        </div>
      </label>
      <Modal
        renderTrigger={({ openModal }) =>
          <div
            onClick={() => openModal()}
            id="eligibleConfirmationModal"
            selected-eligible-value=""
          >
          </div>
        }
        renderContent={({ closeModal }) => (
          <div
            className="order-form-modal-container"
            style={{ padding: '10px' }}
          >
            <span style={{ fontSize: '20px', padding: '20px' }}> The item(s) you are ordering are not currently eligible through insurance, this means you may be responsible for the total cost of the item(s), would you like to proceed and add them to your order? </span>

            <div style={{ textAlign: 'center', paddingTop: '20px' }}>
              <Button
                variant="contained"
                color="error"
                style={{ display: 'inline-block', marginRight: '150px' }}
                onClick={() => closeModal()}
              >
                No
              </Button>
              <Button
                variant="contained"
                style={{ display: 'inline-block' }}
                onClick={() => { handleEligibleConfirmationModal(selectedEquipment, closeModal); }}
              >
                <div style={{ textAlign: 'center' }}>
                  Yes
                </div>
              </Button>
            </div>
          </div>
        )}
      />
    </div>
  );
};

SupplyCheckbox.propTypes = {
  selectedEquipment: PropTypes.arrayOf(PropTypes.string).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  requested_equipment: PropTypes.arrayOf(PropTypes.string).isRequired,
  text: PropTypes.string,
  description: PropTypes.string,
  token: PropTypes.string,
  eligible: PropTypes.bool,
  handleEligibleConfirmationModal: PropTypes.func.isRequired,
  checkHasNotEligibleSelected: PropTypes.func.isRequired,
  handleSelectedSupplies: PropTypes.func.isRequired,
  date: PropTypes.string
};

import React from "react";
import moment from "moment";
import "./styles.scss";
import propTypes from "prop-types"


export const EligibilityIndicator = ({ eligible, date }) => {
  if (eligible == true) return <div className={"eligible"}>Eligible</div>
  else if (eligible == false) {
    return (
      <div className={"notEligible"}>{moment(date).format(
        "MM/DD/YYYY"
      )}</div>
    )
  }
  else {
    return (null)
  }
};

EligibilityIndicator.propTypes = {
  eligible: propTypes.bool,
  date: propTypes.instanceOf(Date)
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card, ListItem } from "components/ui";
import { fromTo } from "utils/misc";
import RescheduleChatBtn from "./reschedule-chat-btn";
import JoinChatBtn from "./join-chat-btn";
import useTranslate from "hooks/use-translate-language";
import { useSelector, useDispatch } from "react-redux";
import selectors from "selectors";
import ClockIcon from "assets/images/clock_gray.png";
import moment from "moment";
import UnconfirmedAppointmentActions from "../shared/unconfirmed-appointment-actions/unconfirmed-appointment-actions"
import ConfirmationModal from "./confirmation-modal";
import { cancelAppointment } from "actions/chat";

const UpcomingChat = ({ chats, master_chat_type }) => {
  const translate = useTranslate();
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const dispatch = useDispatch();
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const userTimeZone = useSelector(selectors.getUserTimeZone);
  const { chat_details, topic_details } = chats[0];
  const is_confirmed = chat_details.meeting_status !== "Un-confirmed" && chat_details.meeting_status !== "declined";
  const is_cancelled = chat_details.meeting_status === "canceled";

  const getStatusColor = (meeting_status) => {

    var statusColor = "white";

    switch (meeting_status) {
      case "completed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "not completed":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "declined":
        statusColor = "rgb(174, 22, 62)";
        break;
      case "rescheduled":
        statusColor = "rgb(19, 180, 255)";
        break;
      case "Un-confirmed":
        statusColor = "rgb(255, 211, 0)";
        break;
      case "canceled":
        statusColor = "rgb(242, 21, 21)";
    }

    return statusColor;
  }

  const diff = moment.duration(
    moment.tz(chat_details.start_time, userTimeZone).diff(moment().tz(userTimeZone))
  );

  const remainingTime = {
    days: moment.utc(diff.as("milliseconds")).format("D") - 1,
    hours: moment.utc(diff.as("milliseconds")).format("H"),
    minutes: moment.utc(diff.as("milliseconds") + 60000).format("mm"),
  };

  const onCancelAppointment = async (slot_id, closeModal) => {
    setDisplaySpinner(true);
    await dispatch(cancelAppointment(slot_id));
    setDisplaySpinner(false);
    closeModal();
  }

  return (
    <Card style={{ margin: 8 }}>
      <div className="upcoming-chat-date-container" style={{ background: getStatusColor(chat_details.meeting_status) }}>
        <span>{moment(chat_details.date_time).format('MMM')}</span>
        <span>{moment(chat_details.date_time).format('DD')}</span>
        <span>{moment(chat_details.date_time).format('yyyy')}</span>
      </div>
      <div className="upcoming-chat-container">
        <div className="chat-header">
          <span>
            {!speaksSpanish
              ? topic_details[0]?.topic_name
              : topic_details[0]?.spanish_topic_name ??
              topic_details[0]?.topic_name}
          </span>
          <span style={{ padding: "0 5px" }}> | </span>
          <span>{translate(master_chat_type)}</span>
          <div className="chat-time">
            <img src={ClockIcon} alt="" />
            <span>
              {fromTo(
                chat_details.start_time,
                chat_details.end_time,
                translate("to")
              )}
            </span>
          </div>
        </div>
        <div className="status-info">
          {!is_confirmed && <span className="status-info-confirmation-pending">{translate("confirmationPending")}</span>}
          {is_cancelled && <span className="status-info-cancelled">{translate("statusCancelled")}</span>}
        </div>
        {!is_cancelled &&
          (diff > 0 ?
            <div className="chat-remaining-time">
              {translate("startsIn")} {remainingTime.days} {translate("days")},{" "}
              {remainingTime.hours} {translate("hours")}, {remainingTime.minutes}{" "}
              {translate("minutes")}
            </div> :
            <div className="chat-remaining-time">{translate("startsNow")}</div>
          )}
      </div>
      <div className="upcoming-chat-content">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
          }}
        >
          <ListItem
            label={translate("topic")}
            value={topic_details[0]?.topic_name}
          />
          {chat_details.description && <ListItem
            label={translate("description")}
            value={chat_details.description}
          />}
          <ListItem
            label={translate("with")}
            value={chat_details.dme_details?.name}
          />
        </div>
        <div className="upcoming-chat-actions">
          {
            !is_cancelled && 
            (
              (remainingTime.days == 0 && remainingTime.hours == 0 && remainingTime.minutes <= 5) || 
              diff <= 0
            ) &&
            <JoinChatBtn {...chat_details} master_chat_type={master_chat_type} />
          }
          {
            !is_cancelled && !chat_details.is_product_demo && chat_details.created_by_patient &&
            <RescheduleChatBtn
              {...chat_details}
              is_confirmed={is_confirmed}
              master_chat_type={master_chat_type}
            />
          }
          {!is_cancelled && !chat_details.created_by_patient && <UnconfirmedAppointmentActions chat_id={chat_details.chat_id} accepted={!is_confirmed ? null : chat_details.meeting_status} />}
          {
            chat_details.can_be_cancelled &&
            <ConfirmationModal
              buttonText="cancelAppointment"
              title="cancelAppointment"
              message="cancelAppointmentConfirmationMessage"
              onYes={(closeModal) => onCancelAppointment(chat_details.slot_id, closeModal)}
              spinner={displaySpinner}
            />
          }
        </div>
      </div>
    </Card>
  );
};

UpcomingChat.propTypes = {
  chats: PropTypes.arrayOf(PropTypes.object),
  master_chat_type: PropTypes.string,
};

export default UpcomingChat;

import { useState, useEffect } from "react";
import selectors from "selectors";
import { useDispatch, useSelector } from "react-redux";
import { fetchPatientProfile } from "actions/user";
import { Page, Modal, Spinner } from "components/ui";
import {
  Fab,
  Paper,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Settings, AddAPhoto } from "@mui/icons-material";
import { propOr } from "ramda";
import browserHistory from "browser-history";
import Info from "./info";
import Insurance from "./insurance";
import Physician from "./physician";
import NotificationSettingsModal from "./notification-settings-modal";
import ChangePasswordModal from "./change-password-modal";
import NoProfileImage from "assets/images/no_profile.png";
import useTranslate from "hooks/use-translate-language";
import "./profile.scss";
import MultifactorOptions from "./multifactor-options";

const useStyles = makeStyles(() => ({
  fab: {
    display: "none", // TODO enable this, upload patient photo
    visibility: "hidden",
    position: "absolute",
    //bottom: theme.spacing(2),
    //right: theme.spacing(2)
  }
}));

const a11yProps = index => ({
  id: `edit-profile-tab-${index}`,
  "aria-controls": `edit-profile-tabpanel-${index}`
});

const MyProfile = () => {
  const profile = useSelector(selectors.getPatientProfile);
  const translate = useTranslate();
  const [isFetching, setIsFetching] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const loadData = async () => {
      try {
        setIsFetching(true);
        await dispatch(fetchPatientProfile());
      } finally {
        setIsFetching(false);
      }
    };
    loadData();
  }, [dispatch]);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Page.Container>
      <Page.Header
        title={translate("myInfo")}
        goBack={"/"}
        action={
          <IconButton
            onClick={handleClick}
            aria-controls="profile-menu"
            aria-label="settings"
            aria-haspopup="true"
          >
            <Settings />
          </IconButton>
        }
      />
      <Page.Content isFetching={isFetching}>
        <div className="profile">
          <Menu
            id={`profile-menu}`}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                browserHistory.push("/profile/edit");
              }}
            >
              {translate("editProfile")}
            </MenuItem>
            <NotificationSettingsModal />
            <ChangePasswordModal />
          </Menu>
          <div className="profile-photo-container">
            <img
              src={propOr(NoProfileImage, "profile_photo", profile)}
              className="profile-photo"
              alt="Profile_Photo"
            />
            <Modal
              renderTrigger={({ openModal }) => (
                <Fab
                  color="primary"
                  aria-label="add"
                  className={classes.fab}
                  onClick={openModal}
                >
                  <AddAPhoto />
                </Fab>
              )}
            >
              <div>content</div>
            </Modal>
          </div>
          <Paper square color="primary">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label="profile-tabs"
              indicatorColor="primary"
            >
              <Tab label={translate("myInfo")} {...a11yProps(0)} />
              <Tab label={translate("insurance")} {...a11yProps(1)} />
              <Tab label={translate("physician")} {...a11yProps(2)} />
              <Tab label={translate("multifactorAuthentication")} {...a11yProps(0)} />
            </Tabs>
          </Paper>
          {isFetching ? (
            <div className="fetching-profile-container">
              <Spinner />
            </div>
          ) : (
            <div className="profile-tab-content">
              {selectedTab === 0 && (
                <Info
                  patientInfo={propOr({}, "patient_information", profile)}
                />
              )}
              {selectedTab === 1 && (
                <Insurance insurances={propOr([], "insurances", profile)} />
              )}
              {selectedTab === 2 && (
                <Physician
                  physicianInfo={propOr({}, "physician_information", profile)}
                />
              )}
              {selectedTab === 3 && (
                <MultifactorOptions
                  patientInfo={propOr({}, "patient_information", profile)}
                />
              )}
            </div>
          )}
        </div>
      </Page.Content>
    </Page.Container>
  );
};

export default MyProfile;

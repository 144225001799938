import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import selectors from "selectors";
import { Spinner } from "components/ui";
import { AppBar, IconButton, Button, Toolbar, Typography } from "@mui/material";
import { Formik } from "formik";
import { SelectInput, TextInput } from "components/form";
import Equipment from "./equipment";
import Dysfunctions from "./dysfunctions";
import { fetchPatientOrderProfile, submitOrder } from "actions/orders";
import { formatAddress } from "utils/misc";
import { nightsPerWeekOptions } from "../options";
import getInitialValues from "./helpers";
import * as Yup from "yup";
import "./duplicate-order-form.scss";
import { makeStyles, withStyles } from "@mui/styles";
import { Close, ExpandMore } from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordion from "@mui/material/Accordion";
import useTranslate from "hooks/use-translate-language";

const useStyles = makeStyles(() => ({
  appBar: {
    position: "relative"
  },
  title: {
    //marginLeft: theme.spacing(2),
    flex: 1
  },
  root: {
    width: "100%"
  },
  heading: {
    //fontSize: theme.typography.pxToRem(16)
  }
}));
const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const DuplicateOrderForm = ({ orderId, handleClose, token }) => {
  const translate = useTranslate();
  const translateRequired = translate("required");
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState();
  const [isFetching, setIsFetching] = useState();
  const profile = useSelector(selectors.getPatientProfile);
  const [expanded, setExpanded] = React.useState(false);

  const companyOrderFormOptions = useSelector(
    selectors.getCompanyOrderFormOptions
  );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    setIsFetching(true);
    dispatch(
      fetchPatientOrderProfile(
        orderId,
        token
          ? {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          : undefined
      )
    )
      .then(order => {
        setOrder(order);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [orderId, dispatch, token]);
  return isFetching || !order ? (
    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <AppBar className="appBar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {translate("reorderSupplies")}
          </Typography>
          <Button color="inherit" disabled>
            {translate("placeOrder")}
          </Button>
        </Toolbar>
      </AppBar>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spinner />
      </div>
    </div>
  ) : (
    <Formik
      initialValues={{
        ...getInitialValues(profile),
        order_create_method: token
          ? "Patient Portal From Email"
          : "Patient Portal"
      }}
      validateOnMount={true}
      onSubmit={async values => {
        await dispatch(
          submitOrder(
            values,
            token
              ? {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`
                }
              : undefined
          )
        );
      }}
      validationSchema={Yup.lazy(() =>
        Yup.object().shape({
          requested_equipment: Yup.array().required(translate("atLeastOne")),
          hours_per_night: Yup.string().required(
            translate("pleaseSelectUsage")
          ),
          nights_per_week: Yup.string().required(
            translate("pleaseSelectUsage")
          ),
          saw_physician_last: Yup.string()
            // .when()
            .required(translate("pleaseSelectUsage")),
          physician_phone: Yup.string().required(translate("enterPhoneNumber")),
          physician_name: Yup.string().required("enterName"),
          physician_address: Yup.object().shape({
            street_address: Yup.string().required(
              translate("pleaseSelectUsage")
            ),
            city: Yup.string().required(translateRequired),
            state: Yup.string().required(translateRequired),
            zip: Yup.string().required(translateRequired)
          }),
          address: Yup.object().shape({
            street_address: Yup.string().required(translateRequired),
            city: Yup.string().required(translateRequired),
            state: Yup.string().required(translateRequired),
            zip: Yup.string().required(translateRequired)
          })
          // dysfunctions: Yup.string().test(
          //   "dysfunctions",
          //   "Eligibility to have insurance cover replacements of these items requires the presence of a dysfunction.",
          //   val => getIsValidDysfunctionAnswers(values)
          // )
        })
      )}
      initialTouched={{
        saw_physician_last: true,
        nights_per_week: true,
        hours_per_night: true
      }}
      enableReinitialize
    >
      {({ handleSubmit, values, isValid, isSubmitting }) => (
        <div className="duplicate-order-form">
          <AppBar className="appBar">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <Close />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {translate("reorderSupplies")}
              </Typography>
              {isSubmitting ? (
                <Spinner />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isValid}
                >
                  {translate("placeOrder")}
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <div className="duplicate-order-container">
            <div
              className="order-form-info"
              style={{ margin: 10, color: "green" }}
            >
              {translate("reviewAndVerify")}
            </div>
            <div
              style={{
                border: "1px solid #ddd",
                padding: 15,
                margin: "1.125rem 10px"
              }}
            >
              {companyOrderFormOptions.saw_physicain_last && (
                <SelectInput
                  label={translate("sawSleepPhysician")}
                  name="saw_physician_last"
                  options={translate("lastSawPhysicianOptions")}
                  style={{ marginBottom: 5 }}
                />
              )}
            </div>
            <div className={classes.root}>
              <Accordion
                expanded={expanded === "supplies"}
                onChange={handleChange("supplies")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="supplies-content"
                  id="header-supplies"
                >
                  <Typography className={classes.heading}>
                    {translate("supplies")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Equipment
                    requested_equipment={values.requested_equipment}
                    supply_period={values.supply_period}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "usage"}
                onChange={handleChange("usage")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="usage-content"
                  id="header-usage"
                >
                  <Typography className={classes.heading}>
                    {translate("usage")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <fieldset className="compliance" style={{ width: "100%" }}>
                    <legend>{translate("tellUsUsage")}</legend>
                    <div
                      style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column"
                      }}
                    >
                      <div
                        className="order-form-warning"
                        style={{ marginTop: 10, marginBottom: 20 }}
                      >
                        {translate("adequateUseOfPAP")}
                      </div>
                      <SelectInput
                        label={translate("hoursPerNightQuestion")}
                        name="hours_per_night"
                        options={translate("hoursPerNightOptions")}
                        style={{ marginBottom: 20 }}
                      />
                      <SelectInput
                        label={translate("nightsPerWeekQuestion")}
                        name="nights_per_week"
                        options={nightsPerWeekOptions}
                        style={{ marginBottom: 20 }}
                      />
                      <SelectInput
                        name="quantity_on_hand"
                        label={translate("insuranceOnHand")}
                        options={translate("quantityOnHandOptions")}
                        style={{ marginTop: 20, maxWidth: "100vw" }}
                      />
                      <TextInput
                        name="other_issues"
                        label={translate("provideInformationOrConcerns")}
                        style={{ width: "100%", marginBottom: 20 }}
                      />
                    </div>
                  </fieldset>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "info"}
                onChange={handleChange("info")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="info-content"
                  id="info-header"
                >
                  <Typography className={classes.heading}>
                    {translate("personalInfo")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    className="duplicate-order-info"
                    style={{ width: "100%" }}
                  >
                    <fieldset className="address">
                      <legend>{translate("address")}</legend>
                      <div>{formatAddress(values.address)}</div>
                    </fieldset>
                    <fieldset className="physician">
                      <legend>{translate("physician")}</legend>
                      <label style={{ fontWeight: "bold" }}>
                        {translate("name")}
                      </label>
                      <div style={{ paddingLeft: 10 }}>
                        {values.physician_name}
                      </div>
                      <label style={{ fontWeight: "bold" }}>
                        {translate("phoneNumber")}
                      </label>
                      <div style={{ paddingLeft: 10 }}>
                        {values.physician_phone}
                      </div>
                      <label style={{ fontWeight: "bold" }}>
                        {translate("address")}
                      </label>
                      <div style={{ paddingLeft: 10 }}>
                        {formatAddress(values.physician_address)}
                      </div>
                    </fieldset>
                    <fieldset className="insurance">
                      <legend>{translate("insurance")}</legend>
                      <div>{values.current_insurance}</div>
                    </fieldset>
                  </div>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "dysfunctions"}
                onChange={handleChange("dysfunctions")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="dysfunctions-content"
                  id="header-dysfunctions"
                >
                  <Typography className={classes.heading}>
                    {translate("dysfunctions")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Dysfunctions order={values} />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default DuplicateOrderForm;

DuplicateOrderForm.propTypes = {
  orderId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  token: PropTypes.string
};

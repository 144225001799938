import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Card } from "components/ui";
import browserHistory from "browser-history";
import { formatDate, fromTo, timeSinceByTimeZone, applicationDateFormat, serverDateFormat, formatDateTimeLocal } from "utils/misc";
import CalandarIcon from "assets/images/calendar.png";
import ClockIcon from "assets/images/clock_gray.png";
import selectors from "selectors";
import "./notification.scss";
import useTranslate from "hooks/use-translate-language";
import UnconfirmedAppointmentActions from "../shared/unconfirmed-appointment-actions/unconfirmed-appointment-actions"

const NotificationItem = (props) => {
  // Give spanish version if you speak spanish but give English if there is no Spanish version
  const speaksSpanish = useSelector(selectors.getSpeaksSpanish);
  const userTimeZone = useSelector(selectors.getUserTimeZone);

  return (
    <Card className="notification-card">
      <div className="notification-header">
        <h4 className="notification-title">
          {!speaksSpanish ? props.title : props.spanish_title ?? props.title}
        </h4>
        <div className="notification-date">
          {timeSinceByTimeZone(props.notification_date, speaksSpanish, userTimeZone)}
        </div>
      </div>
      <div className="notification-content">
        <div>
          <div className="notification-description">
            {!speaksSpanish
              ? props.description
              : props.spanish_description ?? props.description}
          </div>

          <div style={{ float: "left" }}><NotificationTimeStamp {...props} /></div>
          <div style={{ float: "right" }}><NotificationActions {...props} /></div>

        </div>
      </div>
    </Card>
  );
};

NotificationItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  spanish_title: PropTypes.string.isRequired,
  spanish_description: PropTypes.string,
  notification_date: PropTypes.string,
  end_date: PropTypes.string,
  start_date: PropTypes.string,
};

export default NotificationItem;

const NotificationTimeStamp = ({ notification_date, start_date, end_date }) => {
  const translate = useTranslate();
  return (
    <div className="notification-footer">
      <div className="notification-timestamp">
        <img src={CalandarIcon} alt="" />
        {start_date ?
          <span>{formatDate(start_date, applicationDateFormat)}</span> :
          <span>{formatDate(notification_date, applicationDateFormat)}</span>
        }
      </div>
      {start_date && end_date && (
        <div className="notification-timespan">
          <img src={ClockIcon} alt="" />
          <span>{fromTo(start_date, end_date, translate("to"))}</span>
        </div>
      )}
    </div>
  );
};

NotificationTimeStamp.propTypes = {
  notification_date: PropTypes.string.isRequired,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
};

const NotificationActions = (props) => {
  switch (props.notification_type) {
    case 1:
    case 2:
    case 3:
    case 10:
      return formatDate(Date.now(), serverDateFormat).toString() < formatDate(formatDateTimeLocal(props.end_date ? props.end_date : props.start_date), serverDateFormat).toString() ? <UnconfirmedAppointmentActions {...props} /> : null;
    case 12:
      return <div>Cancel Chat</div>;
    case 4:
    case 5:
      return <QuestionnairNotificationActions {...props} />;
    case 18:
      // case 19:
      return <MaskFittingNotificationActions {...props} />;
    case 6:
      return <FileNotificationActions {...props} />;
    case 7:
    case 16:
      return null;
    //return <ProfileNotification {...props} />;
    // case 8:
    // case 9:
    //   return <ArticleNotificationActions {...props} />;
    case 11:
      return <SignatureNotificationActions {...props} />;
    default:
      return null;
  }
};
NotificationActions.propTypes = {
  notification_type: PropTypes.number.isRequired,
  start_date: PropTypes.string,
  end_date: PropTypes.string,
};

const QuestionnairNotificationActions = () => {
  return (
    <div className="notification-actions">
      {/* <Button */}
      {/*   onClick={() => { */}
      {/*     browserHistory.push("/questionnaires"); */}
      {/*   }} */}
      {/*   color="primary" */}
      {/* > */}
      {/*   View Assigned Questionnaires */}
      {/* </Button> */}
    </div>
  );
};
QuestionnairNotificationActions.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  accepted: PropTypes.bool,
};

const MaskFittingNotificationActions = () => (
  <div className="notification-actions">
    <Button
      onClick={() => browserHistory.push("/mask_fitting")}
      color="primary"
    >
      Start Mask Fitting
    </Button>
  </div>
);
MaskFittingNotificationActions.propTypes = {
  title: PropTypes.string,
  accepted: PropTypes.bool,
  description: PropTypes.string,
};

const FileNotificationActions = ({ accepted }) => (
  <div className="notification-actions">
    {!accepted && (
      <Button onClick={() => browserHistory.push("/documents")} color="primary">
        View
      </Button>
    )}
  </div>
);

FileNotificationActions.propTypes = {
  accepted: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

// const ArticleNotificationActions = ({ title, description, ...rest }) => (
//   <div className="notification-actions">
//     <Button onClick={() => console.log("open article")} color="primary">
//       Open
//     </Button>
//   </div>
// );
//
// ArticleNotificationActions.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string
// };
//
const SignatureNotificationActions = ({ accepted }) => (
  <div className="notification-actions">
    {!accepted && (
      <Button
        onClick={() => browserHistory.push("/signatures")}
        color="primary"
      >
        Sign Now
      </Button>
    )}
  </div>
);
SignatureNotificationActions.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  accepted: PropTypes.bool,
};

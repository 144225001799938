import { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { Spinner } from "components/ui";
import { respondToChatNotification } from "actions/chat";
import "./unconfirmed-appointment-actions.scss";
import ConfirmationModal from "components/upcoming-chats/confirmation-modal";


const UnconfirmedAppointmentActions = ({
  chat_id,
  accepted
}) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  return accepted === null ? (
    <div className="unconfirmed-appointment-actions">
      {isSubmitting ? (
        <Spinner />
      ) : (
        <>
          <ConfirmationModal
            title={"declineAppointmentSummary"}
            buttonText={"declineAppointmentActionText"}
            message={"declineAppointmentConfirmation"}
            onYes={async (closeModal) => {
              try {
                closeModal();
                setIsSubmitting(true);
                await dispatch(respondToChatNotification(chat_id, "decline"));
              } finally {
                setIsSubmitting(false);
              }
            }}
            spinner={isSubmitting}
          />

          <Button
            onClick={async () => {
              try {
                setIsSubmitting(true);
                await dispatch(respondToChatNotification(chat_id, "accept"));
              } finally {
                setIsSubmitting(false);
              }
            }}
            color="primary"
          >
            Accept
          </Button>
        </>
      )}
    </div>
  ) : null;
};

UnconfirmedAppointmentActions.propTypes = {
  chat_id: PropTypes.string,
  accepted: PropTypes.bool
};

export default UnconfirmedAppointmentActions;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useTranslate from "hooks/use-translate-language";
import MultifactorOptionsForms from "./multifactor-options-forms";
import { NotificationManager } from "react-notifications";
import { setMFAOptions, verifyPhoneNumber, fetchMFAOptions } from "actions/multifactor";
import { Spinner } from "components/ui";

export default function MultifactorOptions() {
  const [enableVerifyButton, setEnableVerifyButton] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const translate = useTranslate();
  const dispatch = useDispatch();

  const loadData = async () => {
    try {
      setIsFetching(true);
      let response = await dispatch(fetchMFAOptions());
      setPatientInfo(response);
      setEnableVerifyButton(!response.mfa_phone_number_verified)
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [dispatch]);

  const setPhoneNumberApi = async (use_multifactor, phone_number) => {
    let response = await dispatch(
      setMFAOptions({
        Enabled: use_multifactor,
        MFAPhoneNumber: phone_number
      })
    );
    if (response == true) {
      if (!phone_number) {
        NotificationManager.success(translate("MFAUpdated"));
      } else {
        NotificationManager.success(translate("verificationCodeSent"));
        setTimeLeft(120);
      }
    }
  };

  const verifyPhoneNumberApi = async verification_code => {
    let response = await dispatch(
      verifyPhoneNumber({ Code: `${verification_code}` })
    );
    if (response == true) {
      NotificationManager.success(translate("phoneVerificationSucceded"));
      setEnableVerifyButton(false);
      await loadData();
    }
  };

  if (!isFetching && patientInfo) {
    return (
      <div className="profile-panel">
        <MultifactorOptionsForms
          patientInfo={patientInfo}
          setPhoneNumberApi={setPhoneNumberApi}
          verifyPhoneNumberApi={verifyPhoneNumberApi}
          enableVerifyButton={enableVerifyButton}
          setEnableVerifyButton={setEnableVerifyButton}
          timeLeft={timeLeft} 
          setTimeLeft={setTimeLeft}
        ></MultifactorOptionsForms>
      </div>
    );
  } else {
    return <Spinner style={{ marginTop: "1rem" }}></Spinner>;
  }
}

import { useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "components/ui";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Button,
  TextField
} from "@mui/material";
import useTranslate from "hooks/use-translate-language";

import moment from "moment";

import "./schedule-chat.scss";
import DatePicker from "./DatePicker";

const AvailableTimeSlots = ({
  handleTimeSlotSelect,
  availableSlotsResults,
  chat_type,
  date,
  setSelectedDate,
  topicCategory,
  isFetchingTimeslots
}) => {
  const translate = useTranslate();
  const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
  const [isLoadingAppointmentCreation, setIsLoadingAppointmentCreation] =
    useState(false);
  const [description, setDescription] = useState("");

  const slots = availableSlotsResults ? availableSlotsResults.slots : null;

  const extractTimesAndCoachIdFromTimeSlot = selectedTimeSlot => {
    const coachId = selectedTimeSlot.substring(
      0,
      selectedTimeSlot.indexOf("_")
    );
    const start_time = selectedTimeSlot.substring(
      selectedTimeSlot.indexOf("_") + 1,
      selectedTimeSlot.indexOf("/")
    );
    const end_time = selectedTimeSlot.substring(
      selectedTimeSlot.indexOf("/") + 1
    );
    return { coachId, start_time, end_time };
  };

  return (
    <div
      style={{
        marginTop: 25,
        width: "100%",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        height: "auto"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          border: "1px solid #ccc",
          padding: "10px",
          marginBottom: "10em"
        }}
      >
        <div className="calendar-and-timeslots-container">
          <div className="calendar-container">
          <DatePicker  
              date={date} 
              setDate={setSelectedDate} 
              isFetching={isFetchingTimeslots}
              companyAllowsWeekends={availableSlotsResults?.companyAllowsWeekends}
            />
          </div>
          <div className="schedules-list-wrapper">
            <div className="schedules-list-container">
              <FormControl component="fieldset" style={{ width: "100%" }}>
                {isFetchingTimeslots ? (
                  <div className="spinner-container">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {slots != null ? (
                      <RadioGroup
                        aria-label="time-slot"
                        name="time-slot"
                        class="coach-timeslots-container"
                        value={selectedTimeSlot}
                        onChange={({ target }) =>
                          setSelectedTimeSlot(target.value)
                        }
                      >
                        {slots.map(coach =>
                          coach.AvailabilitySlotsTimes.map(slot => {
                            const start_time = moment(slot.Start, "HH:mm").format(
                              "hh:mma"
                            );
                            const end_time = moment(slot.End, "HH:mm").format(
                              "hh:mma"
                            );
                            return (
                              <FormControlLabel
                                value={`${coach.CoachId}_${start_time}/${end_time}`}
                                control={<Radio color="primary" />}
                                label={`${coach.CoachName} | ${start_time.slice(0, 5)} ${start_time.slice(5, 7).toUpperCase()}`}
                                key={`${coach.CoachId}_${start_time}-${end_time}`}
                              />
                            );
                          })
                        )}
                      </RadioGroup>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          color: "red"
                        }}
                      >
                        {translate("noFoundTimeSlots")}
                      </div>
                    )}
                  </>
                )}
              </FormControl>
            </div>
          </div>
        </div>

        <FormControl component="fieldset">
          <TextField
            label={translate("enterDescription")}
            value={description}
            onChange={value => setDescription(value.target.value)}
            margin="normal"
          />
        </FormControl>

        {isLoadingAppointmentCreation ? (
          <Spinner />
        ) : (
          <Button
            disabled={!selectedTimeSlot || isFetchingTimeslots}
            color="primary"
            onClick={async () => {
              try {
                setIsLoadingAppointmentCreation(true);
                const { coachId, start_time, end_time } =
                  extractTimesAndCoachIdFromTimeSlot(selectedTimeSlot);

                await handleTimeSlotSelect({
                  chat_type,
                  topic_category_id: topicCategory,
                  date: moment(date).format("YYYY-MM-DD") + "T00:00:00",
                  description: description,
                  startTime: start_time,
                  endTime: end_time,
                  coachId: coachId
                });
              } finally {
                setIsLoadingAppointmentCreation(false);
              }
            }}
          >
            {translate("submit")}
          </Button>
        )}
      </div>
    </div>
  );
};

AvailableTimeSlots.propTypes = {
  availableSlotsResults: PropTypes.shape({
    message: PropTypes.string,
    slots: PropTypes.arrayOf(PropTypes.object),
    companyAllowsWeekends: PropTypes.bool,
  }),
  chat_type: PropTypes.number,
  topic_category_id: PropTypes.string,
  handleTimeSlotSelect: PropTypes.func,
  date: PropTypes.string,
  setSelectedDate: PropTypes.func,
  topicCategory: PropTypes.string,
  isFetchingTimeslots: PropTypes.bool
};

export default AvailableTimeSlots;

import PropTypes from "prop-types";
import Participant from "./participant";
import { Spinner } from "components/ui";
import VideoImg from "assets/images/video_chat.png";
import "./twilio-video.scss";
import useTranslate from "hooks/use-translate-language";

const TwilioVideoRoom = ({
  members,
  participants,
  connecting,
  error,
  waitForHost,
  publishLocalMedia,
  leaveVideoRoom,
  room
}) => {
  participants = participants.filter(participant => {
    return members.filter(m => m.id === participant.identity)[0]?.name;
  });
  const translate = useTranslate();
  if (connecting)
    return (
      <div className="video-room">
        <Spinner />
      </div>
    );
  else if (error)
    return (
      <div className="video-room">
        <div
          style={{
            color: "red"
          }}
        >
          {error}
        </div>
      </div>
    );
  else
    return (
      <div className="video-room">
        {leaveVideoRoom && (
          <button
            onClick={leaveVideoRoom}
            style={{ position: "absolute", right: 20, top: 10, zIndex: 5 }}
          >
            {translate("leave")}
          </button>
        )}
        {waitForHost &&
          (participants.length <= 0 ||
            participants.filter(emp => emp.audioTracks.size != 0).length <=
              0) && (
            <>
              <img src={VideoImg} alt="" style={{ width: 100, height: 100 }} />
              <div style={{ padding: 10, fontSize: 18 }}>
                {translate("waitingForHost")}
              </div>
            </>
          )}
        {participants.filter(emp => emp.audioTracks.size != 0).length > 0 &&
          participants.map(participant =>
            participant.audioTracks.size > 0 ? (
              <Participant
                members={members}
                key={participant.sid}
                participant={participant}
              />
            ) : (
              <div key={participant.sid} />
            )
          )}
        {room && publishLocalMedia && (
          <Participant
            participant={room.localParticipant}
            members={members}
            localParticipant
          />
        )}
      </div>
    );
};

export default TwilioVideoRoom;

TwilioVideoRoom.defaultProps = { waitForHost: true };

TwilioVideoRoom.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
  participants: PropTypes.arrayOf(PropTypes.object),
  room: PropTypes.object,
  connecting: PropTypes.bool,
  error: PropTypes.bool,
  waitForHost: PropTypes.bool,
  leaveVideoRoom: PropTypes.func,
  publishLocalMedia: PropTypes.bool
};
